/**
 * Represents the latest listing component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'

/**
 * Represents a LatestListing component.
 *
 * @param {object} root0 - Component props.
 * @param {object} root0.data - Listing data.
 * @returns {React.ReactElement} - React component.
 */
const LatestListing = ({ data }) => {
  const { title, productImage, price, listingType, id } = data
  const history = useHistory()

  /**
   * Function add listing url to browser history.
   */
  const handleLatestListingClick = () => {
    history.push(`/annonser/${listingType}/${id}`)
  }

  return (
        <div id="latestListing" data-testid="latest-listing" onClick={handleLatestListingClick}>
          <span>
            <img src={productImage} alt="alt text" />
            <div>
              <h3>{title}</h3>
              <p>{price} kr</p>
              {listingType === 'salj' ? <p>Säljes</p> : <p>Köpes</p>}
            </div>
          </span>
        </div>
  )
}

export default LatestListing
