/**
 * Represents the user queued listings component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import Listings from './Listings.js'
import './userQueuedListings.css'

/**
 * Show listings user are queued on.
 *
 * @returns {React.ReactElement} - React component.
 */
const UserQueuedListings = () => {
  return (
    <div id="userQueuedListings">
      <h1>Annonser som du står i kö på.</h1>
      <p>Om du blir erbjuden en vara så kommer säljaren/köparen att kontakta dig via telefon eller e-post.</p>
      <p>För att lämna en kö, tryck på annonsen och tryck sedan "Lämna kö" på annonssidan.</p>
      <Listings url={`${process.env.REACT_APP_BASE_URL}/queue/inqueue`} />
    </div>
  )
}

export default UserQueuedListings
