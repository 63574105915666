/**
 * Represents the listing type page categories component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import './listingTypePageCategories.css'

/**
 * Listing categories.
 *
 * @param {object} root0 - Component props.
 * @param {Function} root0.updateTitle - Updates title.
 * @returns {React.ReactElement} - React component.
 */
const ListingTypePageCategories = ({ updateTitle }) => {
  const history = useHistory()

  /**
   * Changes to new category.
   *
   * @param {string} listingType - Buy or sell listing.
   * @param {string} category - Category name.
   */
  const changeCategory = (listingType, category) => {
    let buyOrSell
    if (listingType === 'buy') {
      buyOrSell = 'kop'
    } else {
      buyOrSell = 'salj'
    }

    // Build new category url
    let newUrl
    if (category === 'all') {
      newUrl = `/annonser/${buyOrSell}`
    } else {
      newUrl = `/annonser/${buyOrSell}?category=${category}`
    }

    history.push(newUrl) // Open new category
  }

  /**
   * Handles buy category click.
   *
   * @param {object} e - Event object.
   */
  const handleBuyClick = (e) => {
    changeCategory('buy', e.target.className)
  }

  /**
   * Handles sell category click.
   *
   * @param {object} e - Event object.
   */
  const handleSellClick = (e) => {
    changeCategory('sell', e.target.className)
  }
  return (
    <div id="listingTypePageCategories">
      <h2>Kategorier</h2>
      <h3>Köpes</h3>
      <ul>
        <li className="all" onClick={(e) => handleBuyClick(e)}>Alla annonser</li>
        <li className="electronics" onClick={(e) => handleBuyClick(e)}>Elektronik</li>
        <li className="vehicles" onClick={(e) => handleBuyClick(e)}>Fordon</li>
        <li className="leisure" onClick={(e) => handleBuyClick(e)}>Fritid</li>
        <li className="household" onClick={(e) => handleBuyClick(e)}>Hushåll</li>
        <li className="furnishings" onClick={(e) => handleBuyClick(e)}>Inredning</li>
        <li className="clothes" onClick={(e) => handleBuyClick(e)}>Kläder</li>
        <li className="toys" onClick={(e) => handleBuyClick(e)}>Leksaker</li>
        <li className="other" onClick={(e) => handleBuyClick(e)}>Övrigt</li>
      </ul>
      <h3>Säljes</h3>
      <ul>
        <li className="all" onClick={(e) => handleSellClick(e)}>Alla annonser</li>
        <li className="electronics" onClick={(e) => handleSellClick(e)}>Elektronik</li>
        <li className="vehicles" onClick={(e) => handleSellClick(e)}>Fordon</li>
        <li className="leisure" onClick={(e) => handleSellClick(e)}>Fritid</li>
        <li className="household" onClick={(e) => handleSellClick(e)}>Hushåll</li>
        <li className="furnishings" onClick={(e) => handleSellClick(e)}>Inredning</li>
        <li className="clothes" onClick={(e) => handleSellClick(e)}>Kläder</li>
        <li className="toys" onClick={(e) => handleSellClick(e)}>Leksaker</li>
        <li className="other" onClick={(e) => handleSellClick(e)}>Övrigt</li>
      </ul>
    </div>
  )
}

export default ListingTypePageCategories
