/**
 * Represents the my listings component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './myListings.css'
import Listings from './Listings.js'

/**
 * Component represents own listings.
 *
 * @returns {React.ReactElement} - React component.
 */
const MyListings = () => {
  return (
    <div id="myListings">
      <h1>Mina Annonser</h1>
      <Listings owner={true} url={`${process.env.REACT_APP_BASE_URL}/listings/user`} />
    </div>
  )
}

export default MyListings
