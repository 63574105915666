/**
 * Represents the profile component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useEffect, useState, useContext } from 'react'
import './Profile.css'
import Modal from './Modal.js'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Component represents a user profile.
 *
 * @returns {React.ReactElement} - React component.
 */
const Profile = () => {
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const [username, setUsername] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [email, setEmail] = useState('')
  const [city, setCity] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordRepeat, setNewPasswordRepeat] = useState('')
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState('')

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/profile`, {
      method: 'GET',
      credentials: 'include'
    }).then((res) => {
      return res.json()
    }).then((json) => {
      setUsername(json.username)
      setFirstname(json.firstname)
      setLastname(json.lastname)
      setPhoneNumber(json.phoneNumber)
      setEmail(json.email)
      setCity(json.city)
    }).catch((err) => {
      console.error('Error: get user data')
      console.error(err)
    })
  }, [])

  /**
   * Handles updated profile response.
   *
   * @param {number} status - Response status after updated profile.
   * @param {object} json - Response object from profile update.
   */
  const handleResponse = (status, json) => {
    // Response message
    if (status === 400 && json.msg === 'Current password does not match password in database') {
      setModalContent('Nuvarande lösenord är inte korrekt.')
      setModal(true)
    } else if (status === 500) {
      setModalContent('Internt serverfel')
      setModal(true)
    } else if (status === 200) {
      setModalContent('Kontot har uppdaterats')
      setModal(true)
    } else if (status === 400 && json.msg === 'Missing key or keys in request!') {
      setModalContent('Vängligen fyll i alla fält (lösenord krävs ej)')
      setModal(true)
    } else if (status === 400 && json.msg === 'Ett eller flera fält innehåller för många tecken') {
      setModalContent('Ett eller flera textfält har för många tecken')
      setModal(true)
    } else {
      setModalContent('Ett okänt fel har inträffat')
      setModal(true)
    }
  }

  /**
   * Handles update profile submit.
   *
   * @param {object} e - Event object.
   */
  const handleSubmit = (e) => {
    e.preventDefault()
    if (firstname.trim().length > 0 && lastname.trim().length > 0 && phoneNumber.toString().trim().length > 0 && email.trim().length > 0 && city.trim().length > 0) {
      if (firstname.trim().length > 1000 || lastname.trim().length > 1000 || phoneNumber.trim().length > 1000 || email.trim().length > 1000 || city.trim().length > 1000) {
        setModalContent('Ett eller flera fält innehåller för många tecken')
        setModal(true)
      } else {
        const data = {
          firstname,
          lastname,
          phoneNumber,
          email,
          city
        }

        // Check if password has been changed
        if (oldPassword.length > 0) {
          // Update password
          if (newPassword.trim().length > 100 || newPasswordRepeat.trim().length > 100 || oldPassword.trim().length > 100) {
            setModalContent('Ett eller flera lösenordsfält innehåller för många tecken')
            setModal(true)
            return
          } else {
            if (newPassword === newPasswordRepeat) {
              data.newPassword = newPassword
              data.password = oldPassword
            } else {
              setModalContent('Nya lösenorden är inte lika.')
              setModal(true)
              return
            }
          }
        }

        let statusCode = 0
        fetch(`${process.env.REACT_APP_BASE_URL}/auth/profile`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken.csrfToken
          },
          body: JSON.stringify(data)
        }).then(res => {
          statusCode = res.status
          return res.json()
        }).then(json => {
          handleResponse(statusCode, json)
        }).catch(err => {
          console.error(err)
        })
      }
    } else {
      setModalContent('Vängligen fyll i alla fält (lösenord krävs ej)')
      setModal(true)
    }
  }

  return (
    <div id="AccountProfileContainer">
    {modal && <Modal modalContent={modalContent} />}
    <h1>Min profil</h1>
    <p>Användarnamn: {username}</p>
    <form id="loginForm" onSubmit={handleSubmit}>
      <label for="firstname">Förnamn:</label><br>
      </br><input type="text" id="firstname" name="firstname" maxLength="1000" value={firstname} onChange={(e) => setFirstname(e.target.value)}></input><br>
      </br><label htmlFor="lastname">Efternamn:</label><br>
      </br><input type="text" id="lastname" name="lastname" maxLength="1000" value={lastname} onChange={(e) => setLastname(e.target.value)}></input><br>
      </br><label htmlFor="phoneNumber">Telefonnummer:</label><br>
      </br><input type="text" id="phoneNumber" name="phoneNumber" maxLength="1000" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}></input><br>
      </br><label htmlFor="email">E-post:</label><br>
      </br><input type="text" id="email" name="email" maxLength="1000" value={email} onChange={(e) => setEmail(e.target.value)}></input><br>
      </br><label htmlFor="city">Stad:</label><br>
      </br><input type="text" id="city" name="city" maxLength="1000" value={city} onChange={(e) => setCity(e.target.value)}></input><br>
      </br>
      <h2>Byt lösenord</h2>
      <label htmlFor="oldPassword">Nuvarande lösenord:</label><br>
      </br><input type="password" id="oldPassword" name="oldPassword" maxLength="100" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)}></input><br>
      </br><label htmlFor="newPassword">Nytt lösenord:</label><br>
      </br><input type="password" id="newPassword" name="newPassword" maxLength="100" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}></input><br>
      </br><label htmlFor="newPasswordRepeat">Upprepa nytt lösenord:</label><br>
      </br><input type="password" id="newPasswordRepeat" name="newPasswordRepeat" maxLength="100" value={newPasswordRepeat} onChange={(e) => setNewPasswordRepeat(e.target.value)}></input><br>

      </br><button className="loginBtn" type="submit">Ändra</button>
    </form>
    </div>
  )
}

export default Profile
