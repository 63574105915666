/**
 * Represents the listing page delete component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import './removeListing.css'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Represents delete component for listings.
 *
 * @returns {React.ReactElement} - React component.
 */
const ListingPageDelete = () => {
  const [listing, setListing] = useState({})
  const [deleted, setDeleted] = useState(false)
  const [btnClicked, setBtnClicked] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [hasLoaded, setHasLoaded] = useState(false)
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    fetch(`${process.env.REACT_APP_BASE_URL}/listings/auth/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.status === 404 || res.status === 401 || res.status === 500) {
        history.push('./#') // 404 page
      }
      return res.json()
    }).then((json) => {
      setListing(json.foundListing[0]) // Listing to be removed
      setHasLoaded(true)
    }).catch(err => {
      console.error(err)
      setErrorMsg('Ett fel har inträffat')
      setError(true)
      setHasLoaded(true)
    })
  }, [history, id])

  /**
   * Creates error message for user.
   *
   * @param {number} status - A status code.
   */
  const createError = (status) => {
    setError(true)
    setErrorMsg('Error: Listing has not been removed, code: ' + status)
  }

  /**
   * Removes listing from backend.
   */
  const handleRemoveListing = async () => {
    if (btnClicked) { // Buttons is only active once
      return
    }
    setBtnClicked(true)
    await fetch(`${process.env.REACT_APP_BASE_URL}/listings/delete/${listing.id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': csrfToken.csrfToken
      }
    }).then(res => {
      if (res.status === 200) { // Listing has been removed
        setDeleted(true)
      } else { // Listing not removed
        createError(res.status)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  /**
   * Returns to previous page.
   */
  const handleGoBack = () => {
    history.goBack() // Previous page
  }

  const pageContent = (
    <>
      <h1>Tryck på "Ta Bort" för att ta bekräfta borttagning av:</h1>
      <h2>{listing.title}</h2>
      <button id="listingPageDeleteRemoveBtn" onClick={handleRemoveListing}>Ta bort</button>
      <button onClick={handleGoBack}>Gå Tillbaks</button>
    </>
  )

  return (
    <div id="ListingPageDelete">
      {error && <p>{errorMsg}</p>}
      {hasLoaded ? (deleted ? <h1>Annonsen har tagits bort!</h1> : pageContent) : null }
    </div>
  )
}

export default ListingPageDelete
