/**
 * Represents the cookie popup component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import './cookiePopup.css'
import Cookies from 'js-cookie'

/**
 * Function represents the cookie popup component.
 *
 * @returns {React.ReactElement} - React component.
 */
const CookiePopup = () => {
  const [cookie, setCookie] = useState(null)

  /**
   * Adds cookie consent cookie.
   */
  const handleAllowCookies = () => {
    Cookies.set('cookieConsent', 'allow', { expires: 3650 })
    setCookie(null) // Removes cookie popup window
  }

  const popup = (
    <div id="cookiePopup">
      <span>Genom att fortsätta använda webbplatsen godkänner du att vi använder cookies för att hantera användare. Mer information finns <Link to="/cookies">här</Link></span>
      <button onClick={handleAllowCookies}>Jag Förstår</button>
    </div>
  )

  useEffect(() => {
    setCookie(Cookies.get('cookieConsent'))
  }, [])

  return (
    <>
    {cookie === undefined ? popup : null}
    </>
  )
}

export default CookiePopup
