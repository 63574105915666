/**
 * Represents the listing form component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react'
import './listingForm.css'
import Modal from './Modal.js'

/**
 * Represents ListingForm component.
 *
 * @param {object} root0 - Component params.
 * @param {Function} root0.handleSubmit - Post form to backend.
 * @param {object} root0.loadFormContent - Existing form data (for edit).
 * @param {object} root0.modal - Modal message and status.
 * @param {string} root0.title - Form title.
 * @param {string} root0.submitBtnText - Submit form button text.
 * @returns {React.ReactElement} - React component.
 */
const CreateListingForm = ({ handleSubmit, loadFormContent, modal, title, submitBtnText }) => {
  const [formData, setFormData] = useState({})
  const [imageSizeWarning, setImageSizeWarning] = useState('')

  useEffect(() => {
    if (loadFormContent) { // When form data already exist (edit)
      const { title, productImage, price, category, description, listingType, modal, modalContent } = loadFormContent
      setFormData({
        title,
        productImage,
        price,
        category,
        description,
        listingType,
        modal,
        modalContent
      })
    } else { // When formdata does not exist (new listing)
      setFormData({
        title: '',
        productImage: '',
        price: '',
        category: 'kategori 1',
        description: '',
        listingType: 'salj',
        modal: false,
        modalContent: ''
      })
    }
  }, [loadFormContent])

  /**
   * Updates FormData state when form input values changes.
   *
   * @param {object} e - Event object.
   */
  const handleFormChange = (e) => {
    const { name, value } = e
    setFormData({ ...formData, [name]: value })
  }

  /**
   * Turns image into a base64 encoded image.
   *
   * @param {Image} image - Listing image
   */
  const imageHandler = async (image) => {
    if (image.size < 5000001) { // max file size (5MB)
      const reader = new FileReader()

      /**
       * Updates FormData state with base64 image.
       */
      reader.onloadend = () => {
        setFormData({ ...formData, productImage: reader.result })
        if (imageSizeWarning.length !== 0) {
          setImageSizeWarning('')
        }
      }

      if (image) {
        await reader.readAsDataURL(image)
      } else {
        setFormData({ ...formData, productImage: '' })
      }
    } else {
      setImageSizeWarning('För stor bild, max 5MB.')
    }
  }

  return (
    <div id="listingFormContainer">
      {modal.active && <Modal modalContent={modal.content} />}
      <h1>{title}</h1>
      <form id="listingForm" onSubmit={(e) => handleSubmit(e, formData)}>
        <label id="firstFormLabel" htmlFor="title">Titel</label>
        <input type="text" id="title" name="title" maxLength="50" value={formData.title} onChange={(e) => handleFormChange(e.target)} required/>
        <br />
        <input type="radio" id="salj" name="listingType" value="salj" onChange={(e) => handleFormChange(e.target)} checked={formData.listingType === 'salj'} required/>
        <label htmlFor="salj">Sälj</label>
        <input type="radio" id="kop" name="listingType" value="kop" onChange={(e) => handleFormChange(e.target)} checked={formData.listingType === 'kop'} required/>
        <label htmlFor="kop">Köp</label><br />
        <div id="listingFormFileWrapper">
          <label id="firstFormLabel" htmlFor="productImage">Bild</label>
          <div >
            <input type="file" id="productImage" name="productImage" accept="image/*" onChange={(e) => imageHandler(e.target.files[0])} capture/>
            <small>Högst 5MB <br /></small>
            <small id="imageSizeWarning">{imageSizeWarning}</small>
          </div>
          <div>
            {formData.productImage && <img src={formData.productImage} alt="preview" width="150px" height="100px" />}
          </div>
        </div>
        <textarea placeholder="Beskrivning" name="description" maxLength="5000" value={formData.description} onChange={(e) => handleFormChange(e.target)} required/>
        <small id="description-info">{formData.description === undefined ? 0 : formData.description.length} / 5000 tecken</small>
        <br />
        <label id="firstFormLabel" htmlFor="price" >Pris</label>
        <input type="text" id="price" name="price" maxLength="20" value={formData.price} onChange={(e) => handleFormChange(e.target)} required/>
        <br />
        <label id="firstFormLabel" htmlFor="category">Kategori</label>
        <select name="category" id="category" value={formData.category} onChange={(e) => handleFormChange(e.target)} required>
          <option value="choose" hidden>Välj en kategori</option>
          <option value="electronics">Elektronik</option>
          <option value="vehicles">Fordon</option>
          <option value="leisure">Fritid</option>
          <option value="household">Hushåll</option>
          <option value="furnishings">Inredning</option>
          <option value="clothes">Kläder</option>
          <option value="toys">Leksaker</option>
          <option value="other">Övrigt</option>
        </select>
        <br />
        <small>Genom att {submitBtnText.split(' ')[0].toLowerCase()} en annons godkänner jag att mitt användarnamn, telefonnummer, e-post och ort delas med andra besökare på webbplatsen i annonsen.</small>
        <button className="registerFormBtn" type="submit">{submitBtnText}</button>
      </form>
    </div>
  )
}

export default CreateListingForm
