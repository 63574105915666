/**
 * Represents the nav account signed in component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useEffect, useState, useContext } from 'react'
import { useHistory, Link } from 'react-router-dom'
import './NavAccountSignedIn.css'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Represents navbar navigation for logged in users.
 *
 * @param {object} root0 - Component params
 * @param {boolean} root0.setAuth - Updates auth state.
 * @returns {React.ReactElement} - React component.
 */
const NavAccountSignedIn = ({ setAuth }) => {
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const { updateCsrfToken } = useContext(GlobalCsrfTokenStateContext)

  const [username, setUsername] = useState('')
  const history = useHistory()

  useEffect(() => {
    // Get username for navbar
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/username`, {
      method: 'GET',
      credentials: 'include'
    }).then((res) => {
      return res.json()
    }).then((json) => {
      setUsername(json.username)
    }).catch((err) => {
      console.error('Fetch username Error:')
      console.error(err)
    })
  }, [])

  /**
   * Updates auth state after a user has been logged out.
   *
   * @param {object} json - A json resonse.
   */
  const updateAuthState = (json) => {
    if (json.successfulLogout) {
      updateCsrfToken() // New csrf token for the new signed out session cookie
      setAuth(false)
      history.push('/') // Redirect to start page
    }
  }

  /**
   * Handles a user logging out.
   */
  const handleLogout = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/logout`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'CSRF-Token': csrfToken.csrfToken
      }
    }).then(res => {
      return res.json()
    }).then(json => {
      updateAuthState(json)
    }).catch(err => console.error(err))
  }

  return (
    <div id="navSignedIn">
      <div id="dropdownMenu">
        <span>{username} </span>
        <span id="arrow">&#5121;</span>
        <div id="dropdownMenuContent">
          <ul>
            <li>
              <Link id="signedInNavDropDownLink" to="/konto"><p>Mitt Konto</p></Link>
            </li>
            <li>
              <Link id="signedInNavDropDownLink" to="/skapa-annons"><p>Skapa Annons</p></Link>
            </li>
            <li onClick={handleLogout}>
              Logga Ut
            </li>
          </ul>
        </div>
      </div>
      <div id="navSignedInMobile">
        <Link className="link" to="/konto">Mitt Konto</Link>
        <Link className="link" to="/skapa-annons">Skapa Annons</Link>
        <span className="link" onClick={handleLogout}>Logga Ut</span>
      </div>
    </div>
  )
}

export default NavAccountSignedIn
