/**
 * Represents the listing owner menu component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import './listingOwnerMenu.css'

/**
 * Represents ListingOwnerMenu component.
 *
 * @param {object} root0 - Component params.
 * @param {string} root0.id - Unique listing id.
 * @returns {React.ReactElement} - React component.
 */
const ListingOwnerMenu = ({ id }) => {
  const history = useHistory()

  /**
   * Open edit listing page.
   */
  const handleOnEdit = () => {
    history.push(`/annonser/andra/${id}`)
  }

  /**
   * Open remove listing page.
   */
  const handleOnRemove = () => {
    history.push(`/annonser/radera/${id}`)
  }

  /**
   * Open listing owner queue page.
   */
  const handleOnQueue = () => {
    history.push(`/annonser/ko/${id}`)
  }

  return (
    <div className="listingOwnerMenu" id="listingOwnerMenuComponent">
      <ul>
        <li onClick={handleOnRemove}>
          Ta bort
        </li>
        <li onClick={handleOnEdit}>
          Ändra
        </li>
        <li onClick={handleOnQueue}>
          Kö
        </li>

      </ul>
    </div>
  )
}

export default ListingOwnerMenu
