/**
 * Represents the listing page queue component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useContext } from 'react'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Queue component shows place in queue, users in queue and alternative to enter or leave queue.
 *
 * @param {object} root0 - Component props.
 * @param {boolean} root0.auth - If user is logged in.
 * @param {boolean} root0.id - Listing id.
 * @returns {React.ReactElement} - React component.
 */
const ListingPageQueue = ({ auth, id }) => {
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [queueObj, setQueueObj] = useState({})
  const [getQueue, setGetQueue] = useState(true)

  /**
   * Loads queue data.
   */
  const loadQueue = async () => {
    let status
    await fetch(`${process.env.REACT_APP_BASE_URL}/queue/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      status = res.status
      return res.json()
    }).then((json) => {
      if (status === 200) {
        setQueueObj(json)
        setHasLoaded(true)
      }
    }).catch(err => {
      console.error(err)
    })
  }

  if (getQueue) { // Creates new csrf token each time Register component opens.
    loadQueue()
    setGetQueue(false)
  }

  /**
   * Add user to the queue.
   */
  const joinQueue = async () => {
    let status
    await fetch(`${process.env.REACT_APP_BASE_URL}/queue/join/${id}`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': csrfToken.csrfToken
      }
    }).then((res) => {
      status = res.status
      return res.json()
    }).then((json) => {
      if (status === 200) {
        loadQueue()
      }
    }).catch(err => {
      console.error(err)
    })
  }

  /**
   * Removes user from queue.
   */
  const leaveQueue = async () => {
    let status
    await fetch(`${process.env.REACT_APP_BASE_URL}/queue/leave/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': csrfToken.csrfToken
      }
    }).then((res) => {
      status = res.status
      return res.json()
    }).then((json) => {
      if (status === 200) {
        loadQueue()
      }
    }).catch(err => {
      console.error(err)
    })
  }

  const content = (
    <>
      {queueObj.isInQueue ? (<><p>Din plats: <b>{queueObj.placeInQueue}</b></p> <br /> <button id="listingPageSendMessageBtn" style={{ marginTop: '5px' }} onClick={leaveQueue} >Lämna Kö</button> </>) : <button id="listingPageSendMessageBtn" onClick={joinQueue} >Ställ dig i kö</button> }
    </>
  )

  return (
    <div>
      <h2>Kö</h2>
      {hasLoaded && <p>Användare som står i kö:  <b>{queueObj.queue.length}</b></p> }
      {hasLoaded && (queueObj.isOwner ? <p>Det går inte att stå i kö på sin egen annons.</p> : (auth === true ? content : <p>Logga in för att ställa dig i kö.</p>))}
    </div>
  )
}

export default ListingPageQueue
