/**
 * Represents the GDPR info component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { Link } from 'react-router-dom'
import './gdprInfo.css'

/**
 * Function represents the gdpr information component.
 *
 * @returns {React.ReactElement} - React component.
 */
const GdprInfo = () => {
  return (
    <div id="gdprInfo">
      <h1>GDPR</h1>
      <h2>Insamlig av användaruppgifter</h2>
      <p>Denna webbplats samlar in personuppgifter. De uppgifter som lagras är förnamn, efternam, användarnamn, telefonnummer, ort, e-post och lösenord. Telefonnummer, e-post, användarnamn och ort används i annonser för att användare ska kunna kontakta annonsskaparen och veta vart varan finns. I kölistan där annonsskaparen kan se vilka användare som står i kö på annonsen visas användarnamn, e-mail och telefonnummer för att annonsskaparen ska kunna kontakta användaren som står i kö. Förnamn och efternamn används endast vid kontakt med oss eller om en användare rapporteras för att ha begått ett brott (exempelvis sålt stöldgods). Förnamn och efternamn lämnas inte ut till en annan användare.</p>
      <p>Webbplatsen innehåller även cookies. Mer information finns <Link to="/cookies">här</Link>.</p>
      <h2>Hur länge sparas användaruppgifter</h2>
      <p>Eftersom användaruppgifterna är kopplade till ett användarkonto så sparas dessa tills användaren begär borttagning av sitt konto (kan göras genom att kontakta oss <Link to="/hjalp">här</Link>). Uppgifterna som lagras i annonser sparas tills användaren tar bort annonsen.</p>
      <h2>Hur användaruppgifter skyddas</h2>
      <p>Användaruppgifterna skyddas genom att all kommunikation mellan klient (dator) och server sker krypterat med hjälp av https protokollet. Extra känsliga användaruppgifter som exempelvis lösenord krypteras genom hashning och saltning. Webbplatsen använder även skydd mot csrf (cross site request forgery), vilket undviker att falska förfrågningar skickas till servern.</p>
      <h2>Hur kan jag begära ut alla uppgifter som finns lagrade om mig?</h2>
      <p>Alla egna användaruppgifter kan begäras ut genom att kontakta oss via kontaktformuläret <Link to="/hjalp">här</Link>.</p>
    </div>
  )
}

export default GdprInfo
