/**
 * Represents the error component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './Error.css'
import image from '../img/404.jpg'

/**
 * Represents Error component.
 *
 * @returns {React.ReactElement} - React component.
 */
const Error = () => {
  return (
    <div id="error">
      <img src={image} alt="Error 404"></img>
      <br />
      <a href="https://www.freepik.com/vectors/business" target="blank">Business vector created by pikisuperstar - www.freepik.com</a>
    </div>
  )
}

export default Error
