/**
 * Represents the footer component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

/**
 * Represents Footer component.
 *
 * @returns {React.ReactElement} - React component.
 */
const Footer = () => {
  return (
    <div id="footer">
      <div id="sectionContainer">
        <div className="section">
          <h1>Genvägar</h1>
          <ul>
            <li><Link className="footerLink" to="/annonser/kop">Köpes annonser</Link></li>
            <li><Link className="footerLink" to="/annonser/salj">Säljes annonser</Link></li>
            <li><Link className="footerLink" to="/hjalp">hjälp & kontakt</Link></li>
          </ul>
        </div>
        <div className="section">
          <h1>Information</h1>
          <ul>
            <li><Link className="footerLink" to="/villkor">Information om villkor</Link></li>
            <li><Link className="footerLink" to="/gdpr">Information om GDPR</Link></li>
            <li><Link className="footerLink" to="/cookies">Information om Cookies</Link></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Footer
