/**
 * Represents the start shortcuts component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import './StartShortcuts.css'

/**
 * Represents shortcuts to listing categories.
 *
 * @returns {React.ReactElement} - React component.
 */
const StartShortCuts = () => {
  const history = useHistory()

  /**
   * Opens buy or sell page.
   *
   * @param {object} e - Event object.
   */
  const handleClick = (e) => {
    history.push(`/annonser/${e.target.id}`) // Changes url to the new page
  }

  return (
    <div id="startShortcuts">
      <div id="btnContainer">
        <button id="kop" onClick={(e) => handleClick(e)} >Köpes Annonser</button>
        <button id="salj" onClick={(e) => handleClick(e)} >Säljes Annonser</button>
      </div>
    </div>
  )
}

export default StartShortCuts
