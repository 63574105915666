/**
 * Represents the help form component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './helpForm.css'

/**
 * Represents Form component (help page).
 *
 * @returns {React.ReactElement} - React component.
 */
const Form = () => {
  return (
    <div id="formComponent">
      <h2>Skicka meddelande</h2>
        <form id="questionForm" name="contact" method="POST" action="/" data-netlify="true" >
          <input type="hidden" name="form-name" value="contact" />
          <label htmlFor="title">Rubrik:</label><br/>
          <input type="text" id="title" name="title" placeholder="Rubrik" required></input><br/>
          <label htmlFor="subject">Ämne:</label><br/>
          <input type="text" id="subject" name="subject" placeholder="Ämne" required></input><br/>
          <label htmlFor="email">Epost:</label><br/>
          <input type="text" id="email" name="email" placeholder="E-post" required></input><br/>
          <label htmlFor="message">Meddelande:</label><br/>
          <textarea name="message" placeholder="Meddelande" required/><br/>
          <button className="questionSubmit" type="submit">Skicka Meddelande</button>
        </form>
    </div>
  )
}

export default Form
