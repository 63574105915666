/**
 * Represents the create listing component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useContext } from 'react'
import ListingForm from './ListingForm.js'
import CreateListingSuccess from './CreateListingSuccess.js'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Function represents CreateListing component.
 *
 * @returns {React.ReactElement} - React component.
 */
const CreateListing = () => {
  const [success, setSuccess] = useState(false)
  const [newListingId, setNewListingId] = useState(null)
  const [buyOrSell, setBuyOrSell] = useState(null)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [modal, setModal] = useState({ active: false, content: '' })

  const csrfToken = useContext(GlobalCsrfTokenStateContext)

  /**
   * Resets form.
   */
  const handleReset = () => {
    setDisableSubmit(false)
    setSuccess(false)
  }

  /**
   * Submits form to backend.
   *
   * @param {object} e - Submit event.
   * @param {object} formData - Form data.
   */
  const handleSubmit = (e, formData) => {
    e.preventDefault()
    if (!disableSubmit) {
      setDisableSubmit(true)
      // Validate form data
      if ((formData.title.trim().length > 0 && formData.title.trim().length <= 50) && formData.productImage.length > 0 && (formData.description.trim().length > 0 && formData.description.trim().length <= 5000) && formData.category.trim().length > 0 && (formData.price.trim().length > 0 && formData.price.trim().length <= 20) && formData.listingType.trim().length > 0) {
        const { category } = formData
        if (!(category === 'electronics' || category === 'vehicles' || category === 'leisure' || category === 'household' || category === 'furnishings' || category === 'clothes' || category === 'toys' || category === 'other' || category === 'choose')) {
          setModal({ active: true, content: 'Ogiltig kategori' })
          setDisableSubmit(false)
          return
        }
        // Create listing
        let status = null
        fetch(`${process.env.REACT_APP_BASE_URL}/listings/create`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken.csrfToken
          },
          body: JSON.stringify(formData)
        }).then(res => {
          status = res.status
          return res.json()
        }).then(json => {
          if (status === 200) {
            setNewListingId(json.newListingId)
            setBuyOrSell(formData.listingType)
            setSuccess(true)
          } else {
            console.error('Status inte 200!')
          }
        }).catch(err => console.error(err))
      } else {
        setModal({ active: true, content: 'Fyll i alla fält!' })
        setDisableSubmit(false)
      }
    } else {
      setModal({ active: true, content: 'En annons skapas redan!' })
      console.error('skapa annons tryckt mer än en gång innan respons!')
    }
  }

  return (
    <div id="createListingContainer">
      {success ? <CreateListingSuccess handleReset={handleReset} buyOrSell={buyOrSell} newListingId={newListingId} /> : <ListingForm title='Skapa Annons' submitBtnText='Skapa Annons' modal={modal} handleSubmit={handleSubmit} />}
    </div>
  )
}

export default CreateListing
