/**
 * Represents the listings component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react'
import './Listings.css'
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import Listing from './Listing.js'
import PaginationMenu from './PaginationMenu.js'

/**
 * Represents component listing all listings from an url.
 *
 * @param {object} root0 - Component props.
 * @param {string} root0.url - The url to fetch listings from.
 * @param  {string} root0.owner - Name of the listing owner.
 * @returns {React.ReactElement} - React component.
 */
const Listings = ({ url, owner }) => {
  const [listings, setListings] = useState([])
  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [invalidCategory, setInvalidCategory] = useState(false)
  const [totalPages, setTotalPages] = useState(null)
  const [fetchUrl, setFetchUrl] = useState(url)
  const location = useLocation()

  useEffect(() => {
    setFetchUrl(url)
  }, [url])

  useEffect(() => {
    // Get listings from url
    let status
    fetch(fetchUrl, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      status = res.status
      return res.json()
    }).then((json) => {
      if (status === 200) {
        setListings(json.foundListings)
        setTotalPages(json.totalPages)
        if (json.msg === 'Invalid category') {
          setInvalidCategory(true)
        }
        setLoaded(true)
      } else if (status === 400) {
        setErrorMessage('En eller flera url parametrar är för långa!')
        setError(true)
      }
    }).catch(err => {
      console.error(err)
      if (err) {
        setErrorMessage('Ett fel har inträffat, försök igen senare.')
        setError(true)
      }
    })
  }, [fetchUrl])

  /**
   * Open new another page.
   *
   * @param {number} pageIndex - Index of page to open.
   */
  const changePage = (pageIndex) => {
    const query = queryString.parse(location.search)
    if (Object.keys(query).length > 0) {
      setFetchUrl(`${url}&page=${pageIndex}`)
    } else {
      setFetchUrl(`${url}?page=${pageIndex}`)
    }
  }

  return (
    <div id="listings">
      {error && <h2>{errorMessage}</h2>}
      {(loaded && listings !== undefined) ? (listings.length > 0 ? listings.map((listing, i) => { return <Listing key={i} listing={listing} owner={owner} /> }) : <h2>Inga annonser hittades.</h2>) : (invalidCategory && <h2>Kategorin existerar inte</h2>)}
      {loaded && <PaginationMenu totalPages={totalPages} changePage={changePage} />}
    </div>
  )
}

export default Listings
