/**
 * Represents the modal component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './Modal.css'

/**
 * Component represents a modal.
 *
 * @param {object} root0 - Component params.
 * @param {string} root0.modalContent - Modal content.
 * @returns {React.ReactElement} - React component.
 */
const Modal = ({ modalContent }) => {
  return (
    <div id="modal">
      <p>{modalContent}</p>
    </div>
  )
}

export default Modal
