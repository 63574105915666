/**
 * Represents the terms information component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './termsInfo.css'
/**
 * Function represents the terms and conditions component.
 *
 * @returns {React.ReactElement} - React component.
 */
const TermsInfo = () => {
  return (
    <div id="termsInfo">
      <h1>Villkor</h1>
      <ol>
        <li>En användare får endast skapa ett konto</li>
        <li>Ett konto får inte innehålla falska användaruppgifter. Exempelvis falskt namn.</li>
        <li>Det är endast tillåtet att köpa/sälja lagliga varor</li>
        <li>Annonser med stöldgods är ej tillåtet.</li>
        <li>Falska annonser får ej skapas. Exempelvis bedrägeri annonser eller varor som inte finns.</li>
        <li>Bilder som laddas upp till webbplatsen måste ägas av annonsskaparen. Alternativt har användaren tillstånd att använda bilden (användaren måste ha bevis).</li>
      </ol>
      <p>De användare som bryter mot en eller flera dessa regler kommer att bli avstängd permanent från webbplatsen.</p>
    </div>
  )
}

export default TermsInfo
