/**
 * Represents the create listing success component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import './createListingSuccess.css'

/**
 * Function represents CreateListingSuccess component.
 *
 * @param {object} root0 - Component props.
 * @param {Function} root0.handleReset - Resets state to form.
 * @param {string} root0.buyOrSell - Listing type.
 * @param {string} root0.newListingId - New listing id.
 * @returns {React.ReactElement} - React component.
 */
const CreateListingSuccess = ({ handleReset, buyOrSell, newListingId }) => {
  const history = useHistory()

  /**
   * Opens created listing.
   */
  const handleOpenListing = () => {
    history.push(`/annonser/${buyOrSell}/${newListingId}`)
  }

  /**
   * Resets form.
   */
  const handleCreateNewListing = () => {
    handleReset()
  }

  return (
    <div id="createListingSuccess">
      <h1>Annonsen har skapats!</h1>
      <p>Tryck på knappen för att visa annonsen.</p>
      <button className="successBtn" onClick={handleOpenListing}>Visa Annons</button>
      <button className="successBtn" onClick={handleCreateNewListing}>Skapa Ny Annons</button>
    </div>
  )
}

export default CreateListingSuccess
