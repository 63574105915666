/**
 * Represents the search component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import './Search.css'
import queryString from 'query-string'

/**
 * Represents a search component.
 *
 * @returns {React.ReactElement} - React component.
 */
const Search = () => {
  const history = useHistory()

  /**
   * Handles search submit.
   *
   * @param {object} e - Post search event object.
   */
  const handleSearchSubmit = (e) => {
    e.preventDefault()
    const query = e.target.searchQuery.value
    const listingType = e.target.listingType.value

    if (query.trim().length > 0 && (listingType === 'buy' || listingType === 'sell')) {
      const queryObj = { query, listingType }
      const queryParams = queryString.stringify(queryObj)
      history.push(`/annonser/sok?${queryParams}`) // Opens search result page with query as param.
    }
  }

  return (
    <div id="searchComponent" >
      <form id="startSearchForm" onSubmit={handleSearchSubmit}>
        <input className="startSearchInput" type="text" name="searchQuery" id="searchQuery" placeholder="Sök" maxLength="1000" required/>

        <input className="radioBtn" type="radio" id="buy" name="listingType" value="buy" required/>

        <label htmlFor="buy">Köpes</label><br />

        <input className="radioBtn" type="radio" id="sell" name="listingType" value="sell" required/>

        <label htmlFor="sell">Säljes</label><br />

        <input className="startSearchInput" type="submit" id="searchButton" value=" Sök"/>
      </form>
    </div>
  )
}

export default Search
