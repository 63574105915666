/**
 * Represents the register component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useContext } from 'react'
import './Register.css'
import isemail from 'isemail'
import { Link } from 'react-router-dom'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Represent register account form.
 *
 * @param {object} root0 - Component props.
 * @param {Function} root0.openLogin - Opens login form.
 * @param {boolean} root0.setModal - Activates or disables modal.
 * @param {string} root0.setModalContent - Updates modal content.
 * @returns {React.ReactElement} - React component.
 */
const Register = ({ openLogin, setModal, setModalContent }) => {
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const { updateCsrfToken } = useContext(GlobalCsrfTokenStateContext)
  const [getNewCsrfToken, setGetNewCsrfToken] = useState(true)

  if (getNewCsrfToken) { // Creates new csrf token each time Register component opens.
    updateCsrfToken()
    setGetNewCsrfToken(false)
  }

  const [firstname, setFirstName] = useState('')
  const [lastname, setLastname] = useState('')
  const [username, setUsername] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [password, setPassword] = useState('')
  const [passwordRepeat, setPasswordRepeat] = useState('')
  const [email, setEmail] = useState('')
  const [city, setCity] = useState('')

  /**
   * Handles response after register account submit.
   *
   * @param {object} json - Submit register account response.
   */
  const handleSubmitResponse = (json) => {
    // Response message
    if (json.status === 200) {
      openLogin()
    } else if (json.status === 400 || json.status === 409 || json.status === 500) {
      setModalContent(json.msg)
      setModal(true)
    } else {
      setModalContent('Okänt fel, försök igen senare.')
      setModal(true)
    }
  }

  /**
   * Handles Submit to register account.
   *
   * @param {object} e - Submit event object.
   */
  const handleSubmit = (e) => {
    e.preventDefault()
    // Validate form data
    if (firstname.trim().length > 0 && lastname.trim().length > 0 && username.trim().length > 0 && phoneNumber.trim().length > 0 && password.trim().length > 0 && passwordRepeat.trim().length > 0 && email.trim().length > 0 && city.trim().length > 0) {
      if (firstname.trim().length > 1000 || lastname.trim().length > 1000 || username.trim().length > 20 || phoneNumber.trim().length > 1000 || password.trim().length > 100 || passwordRepeat.trim().length > 100 || email.trim().length > 1000 || city.trim().length > 1000) {
        setModalContent('Ett eller flera fält innehåller för lång text')
        setModal(true)
      } else {
        if (password === passwordRepeat) {
          if (isemail.validate(email)) {
            const userData = { firstname, lastname, username, phoneNumber, password, email, city }
            fetch(`${process.env.REACT_APP_BASE_URL}/auth/register`, {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
                'CSRF-Token': csrfToken.csrfToken
              },
              body: JSON.stringify(userData)
            }).then(res => {
              return res.json()
            }).then(json => {
              handleSubmitResponse(json)
            }).catch(err => console.error(err))
          } else {
            setModalContent('Ange en korrekt email adress!')
            setModal(true)
          }
        } else {
          setModalContent('Lösenorden matchar inte.')
          setModal(true)
        }
      }
    } else {
      setModalContent('Vänligen fyll i alla fält!')
      setModal(true)
    }
  }

  return (
    <>
        <div>
          <form id="registerForm" onSubmit={handleSubmit}>

            <div className="registerFormRow">
              <div className="registerFormColumn">
                <label id="firstFormLabel" htmlFor="firstname">Förnamn</label>
                <input type="text" id="firstname" name="firstname" maxLength="1000" value={firstname} onChange={(e) => setFirstName(e.target.value)}></input>
              </div>
              <div className="registerFormColumn">
                <label htmlFor="lastname">Efternamn</label>
                <input type="text" id="lastname" name="lastname" maxLength="1000" value={lastname} onChange={(e) => setLastname(e.target.value)}></input>
              </div>
            </div>
            <div className="registerFormRow">
              <div className="registerFormColumn">
                <label htmlFor="username">Användarnamn</label>
                <input type="text" id="username" name="username" maxLength="20" value={username} onChange={(e) => setUsername(e.target.value)}></input>
              </div>
              <div className="registerFormColumn">
                <label htmlFor="Telefonnummer">Telefonnummer</label>
                <input type="text" id="Telefonnummer" name="Telefonnummer" maxLength="1000" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}></input>
              </div>
            </div>
            <div className="registerFormRow">
              <div className="registerFormColumn">
                <label htmlFor="email">E-post</label>
                <input type="text" id="email" name="email" maxLength="1000" value={email} onChange={(e) => setEmail(e.target.value)}></input>
              </div>
              <div className="registerFormColumn">
                <label htmlFor="city">Ort</label>
                <input type="text" id="city" name="city" maxLength="1000" value={city} onChange={(e) => setCity(e.target.value)}></input>
              </div>
            </div>
            <div className="registerFormRow">
              <div className="registerFormColumn">
                <label htmlFor="password">Lösenord</label>
                <input type="password" id="password" name="password" maxLength="100" value={password} onChange={(e) => setPassword(e.target.value)}></input>
              </div>
              <div className="registerFormColumn">
                <label htmlFor="passwordRepeat">Upprepa lösenord</label>
                <input type="password" id="passwordRepeat" name="passwordRepeat" maxLength="100" value={passwordRepeat} onChange={(e) => setPasswordRepeat(e.target.value)}></input>
              </div>
              <br />
            </div>
            <small id="acceptMessage">Genom att skapa ett konto godkänner jag webbplatsens <Link className="accountWindowLink" to="/villkor" target="_blank">villkor</Link>, <Link className="accountWindowLink" to="/cookies" target="_blank">cookie policy</Link> samt <Link className="accountWindowLink" to="/cookies" target="_blank">hur personuppgifter hanteras (GDPR)</Link></small>
            <button className="registerFormBtn" type="submit">Skapa konto</button>
          </form>
        </div>
        <div id="registerFormHasAccountContainer">
          <p id="registerFormHasAccount">Har du redan ett konto?</p>
          <button className="registerFormBtn" onClick={openLogin} >Logga in</button>
        </div>
    </>
  )
}

export default Register
