/**
 * Represents the search result component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import queryString from 'query-string'
import './searchResult.css'
import Listings from './Listings.js'
import Search from './Search.js'

/**
 * Component renders search results based on query params.
 *
 * @returns {React.ReactElement} - React component.
 */
const SearchResult = () => {
  const location = useLocation()
  const [params, setParams] = useState({})

  // Build page title
  let type
  if (params.listingType === 'buy') {
    type = 'Köpes'
  } else if (params.listingType === 'sell') {
    type = 'Säljes'
  }

  useEffect(() => {
    setParams(queryString.parse(location.search))
  }, [location.search])

  return (
    <div id="searchResult">
      <div id="searchContainer">
        <Search />
      </div>
      {params.query && (params.query.length > 1000 ? null : <h1>{type} annonser som matchar sökorden:</h1>)}
      {params.query && (params.query.length > 1000 ? null : <h2>{params.query}</h2>)}
      <Listings url={`${process.env.REACT_APP_BASE_URL}/listings/search${location.search}`} />
    </div>
  )
}

export default SearchResult
