/**
 * Represents the navbar component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './Navbar.css'
import logo from './../logo.png'
import Login from './Login.js'
import Register from './Register.js'
import AccountWindow from './AccountWindow.js'
import NavAccountSignedOut from './NavAccountSignedOut.js'
import NavAccountSignedIn from './NavAccountSignedIn.js'

/**
 * Component represents the navbar.
 *
 * @param {object} root0 - Component props.
 * @param {boolean} root0.auth - If the user is authorized.
 * @param {boolean} root0.setAuth - Updates auth state.
 * @param {boolean} root0.authLoaded - auth response from backend.
 * @returns {React.ReactElement} - React component.
 */
const Navbar = ({ auth, setAuth, authLoaded }) => {
  const [loginWindow, setLoginWindow] = useState(false)
  const [registerWindow, setRegisterWindow] = useState(false)
  const [toggleMobileNav, setToggleMobileNav] = useState(false)
  const [mobileNavbtnText, setMobileNavBtnText] = useState('Öppna Meny')

  /**
   * Opens login component.
   */
  const openLogin = () => {
    if (loginWindow === false && registerWindow === false) {
      setLoginWindow(true)
    }
    if (loginWindow === false && registerWindow === true) {
      setRegisterWindow(false)
      setLoginWindow(true)
    }
  }

  /**
   * Closes login component.
   */
  const closeLogin = () => {
    if (loginWindow === true) {
      setLoginWindow(false)
    }
  }

  /**
   * Opens register component.
   */
  const openRegister = () => {
    if (loginWindow === false && registerWindow === false) {
      setRegisterWindow(true)
    }

    if (loginWindow === true && registerWindow === false) {
      setLoginWindow(false)
      setRegisterWindow(true)
    }
  }

  /**
   * Closes register component.
   */
  const closeRegister = () => {
    if (registerWindow === true) {
      setRegisterWindow(false)
    }
  }

  /**
   * Toggle mobile nav menu.
   */
  const handleToggleMobileNav = () => {
    if (!toggleMobileNav) {
      setToggleMobileNav(true)
      setMobileNavBtnText('Stäng Meny')
    }

    if (toggleMobileNav) {
      setToggleMobileNav(false)
      setMobileNavBtnText('Öppna Meny')
    }
  }

  return (
    <div>
      <div id="navBar">
        <div id="navLogo" className="navbarElements">
          <div style={{ textAlign: 'center' }}>
          <img src={logo} alt="logo" />
          </div>
        </div>
        <div id="navFlexContainer">
          <div id="globNav" className="navbarElements">
            <ul>
              <li>
                <Link className="link" to="/"><p>Start</p></Link>
              </li>
              <li>
                <Link className="link" to="/annonser/kop"><p>Köpes</p></Link>
              </li>
              <li>
                <Link className="link" to="/annonser/salj"><p>Säljes</p></Link>
              </li>
              <li>
                <Link className="link" to="/hjalp"><p>Hjälp</p></Link>
              </li>
            </ul>
          </div>
          <div id="login" className="navbarElements">
            {authLoaded && (auth ? <NavAccountSignedIn setAuth={setAuth} /> : <NavAccountSignedOut openLogin={openLogin} openRegister={openRegister} />)}

          </div>
        </div>
        <div id="navMobile">
            <img src={logo} alt="logo" />
            <div id="toggleMenu" onClick={handleToggleMobileNav}>
              <span>{mobileNavbtnText}</span>
            </div>
            {toggleMobileNav && <>
              <div id="btns" onClick={() => handleToggleMobileNav()}>
                <Link className="link" to="/">Start</Link>
                <Link className="link" to="/annonser/kop">Köpes</Link>
                <Link className="link" to="/annonser/salj">Säljes</Link>
                <Link className="link" to="/hjalp">Hjälp</Link>
                {authLoaded && (auth ? <NavAccountSignedIn setAuth={setAuth} /> : <NavAccountSignedOut openLogin={openLogin} openRegister={openRegister} />)}
              </div>
            </>}
        </div>
        {loginWindow ? <AccountWindow setAuth={setAuth} close={closeLogin} openRegister={openRegister} title="Logga in" Component={Login} /> : null}
        {registerWindow ? <AccountWindow setAuth={setAuth} close={closeRegister} openLogin={openLogin} title="Skapa konto" Component={Register} /> : null}
      </div>
      <div id="navMargin"></div>
    </div>
  )
}

export default Navbar
