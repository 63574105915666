/**
 * Represents the listing list page component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useEffect, useState, useCallback } from 'react'
import './listingListPage.css'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import Latest from './Latest.js'
import Listings from './Listings.js'
import Search from './Search.js'
import ListingTypePageCategories from './ListingTypePageCategories.js'

/**
 * Component represents sell page.
 *
 * @returns {React.ReactElement} - React component.
 */
function Sell () {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const history = useHistory()
  const { type } = useParams()
  const [loaded, setLoaded] = useState(false)
  const [listingType, setListingType] = useState('')
  const [category, setCategory] = useState('')
  const [title, setTitle] = useState('')

  const updateTitle = useCallback(() => {
    const category = queryString.parse(location.search).category
    let newTitle

    // Set listing type
    if (type === 'kop') {
      newTitle = 'Köpes annonser: '
    } else {
      newTitle = 'Säljes annonser: '
    }

    // Set category
    if (category === undefined) {
      newTitle += 'alla annonser'
    } else if (category === 'electronics') {
      newTitle += 'elektronik'
    } else if (category === 'vehicles') {
      newTitle += 'fordon'
    } else if (category === 'leisure') {
      newTitle += 'fritid'
    } else if (category === 'household') {
      newTitle += 'hushåll'
    } else if (category === 'furnishings') {
      newTitle += 'inredning'
    } else if (category === 'clothes') {
      newTitle += 'kläder'
    } else if (category === 'toys') {
      newTitle += 'leksaker'
    } else if (category === 'other') {
      newTitle += 'övrigt'
    } else {
      newTitle += category
    }
    setTitle(newTitle)
  }, [location.search, type])

  useEffect(() => {
    const query = queryString.parse(location.search)
    if (query.category) {
      setCategory(`?category=${query.category}`)
    }
    if (type === 'kop') {
      setListingType('buy')
      setLoaded(true)
    } else if (type === 'salj') {
      setListingType('sell')
      setLoaded(true)
    } else {
      history.push('/err')
    }
    updateTitle()
  }, [type, location, history, updateTitle])

  return (
    <div id="sellMain">
      {loaded &&
      <>
        <div id="titleContainer">
          <h1>{title}</h1>
        </div>
        <div id="sellCategories">
          <ListingTypePageCategories updateTitle={updateTitle} />
        </div>
        <div style={{ borderLeft: 'none' }} id="sellSearch">
          <Search />
        </div>
        <div id="sellListings">
          {query.category ? <Listings url={`${process.env.REACT_APP_BASE_URL}/listings/${listingType + category}`} /> : <Listings url={`${process.env.REACT_APP_BASE_URL}/listings/${listingType}`} />}
        </div>
        <div id="sellLatest">
          <Latest id="latestComponent" />
        </div>
      </>
      }
    </div>
  )
}

export default Sell
