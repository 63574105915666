/**
 * Represents the start search component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './StartSearch.css'
import Search from './Search.js'

/**
 * Component represents search container for start page.
 *
 * @returns {React.ReactElement} - React component.
 */
const StartSearch = () => {
  return (
    <div id="searchStart">
      <Search />
    </div>
  )
}

export default StartSearch
