/**
 * Represents the main component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Start from './components/Start'
import Sell from './components/ListingListPage'
import Help from './components/Help'
import Error from './components/Error'
import Account from './components/Account'
import CreateListing from './components/CreateListing'
import ListingPage from './components/ListingPage'
import EditListing from './components/EditListing'
import RemoveListing from './components/RemoveListing'
import SearchResult from './components/SearchResult'
import QueuePage from './components/QueuePage'
import CookieInfo from './components/CookieInfo'
import GdprInfo from './components/GdprInfo'
import TermsInfo from './components/TermsInfo'
import GlobalCsrfTokenStateContext from './contexts/GlobalCsrfTokenStateContext'
import CookiePopup from './components/CookiePopup'

/**
 * Function represents the main component of the application.
 *
 * @returns {React.ReactElement} - React component.
 */
function App () {
  const [auth, setAuth] = useState(false) // If user is logged in
  const [authLoaded, setAuthLoaded] = useState(false) // If auth data has been loaded

  const [csrfToken, setCsrfToken] = useState('')

  /**
   * Updates csrf token.
   *
   */
  const updateCsrfToken = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/auth/csurf`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'No-Store'
      }
    }).then((res) => {
      return res.json()
    }).then((json) => {
      setCsrfToken(json.csrfToken)
    }).catch(err => {
      console.error(err)
    })
  }

  /**
   * Sets auth state to true if the user is logged in.
   *
   * @param {object} json - A response object.
   */
  const handleAuthCheck = (json) => {
    const { isAuth } = json
    if (isAuth) {
      setAuth(true)
    } else if (!isAuth) {
      setAuth(false)
    } else {
      console.error('Auth: Ett fel har inträffat')
    }
    setAuthLoaded(true)
  }

  useEffect(() => {
    updateCsrfToken()
    // Check if auth
    fetch(`${process.env.REACT_APP_BASE_URL}/auth/check`, {
      method: 'GET',
      credentials: 'include'
    }).then(res => {
      return res.json()
    }).then(json => {
      handleAuthCheck(json)
    }).catch(err => console.error(err))
  }, [])

  return (
      <Router>
        <CookiePopup />
        <GlobalCsrfTokenStateContext.Provider value={{ csrfToken, updateCsrfToken }}>
          <Navbar setAuth={setAuth} auth={auth} authLoaded={authLoaded} /> {/* byt till useContext istället för att skicka setAuth till navbar som bara skickar vidare */}
          <Switch>
            <Route exact path="/">
              <Start />
            </Route>
            <Route exact path="/annonser/andra/:id">
              <EditListing />
            </Route>
            <Route exact path="/annonser/radera/:id">
              <RemoveListing />
            </Route>
            <Route exact path="/annonser/sok">
              <SearchResult />
            </Route>
            <Route exact path="/annonser/ko/:id">
              <QueuePage />
            </Route>
            <Route exact path="/annonser/:type">
              <Sell />
            </Route>
            <Route exact path="/hjalp">
              <Help />
            </Route>
            <Route exact path="/annonser/:type/:id">
              <ListingPage auth={auth} />
            </Route>
            <ProtectedAccount exact path="/konto" auth={auth} component={Account} />
            <ProtectedAccount exact path="/skapa-annons" auth={auth} component={CreateListing} />
            <Route exact path="/cookies">
              <CookieInfo />
            </Route>
            <Route exact path="/gdpr">
              <GdprInfo />
            </Route>
            <Route exact path="/villkor">
              <TermsInfo />
            </Route>
            <Route path="*">
              <Error />
            </Route>
          </Switch>
          <Footer />
        </GlobalCsrfTokenStateContext.Provider>
      </Router>
  )
}

/**
 * Represents a protected route for authorized users.
 *
 * @param {object} root0 - Props for the component.
 * @param {boolean} root0.auth - A boolean if a user is authorized.
 * @param {React.Component} root0.component - A react component.
 * @param {string} root0.path - A path to the component in the router.
 * @returns {React.ReactElement} - React component.
 */
const ProtectedAccount = ({ auth, component: Component, path }) => {
  return <Route exact path={path} render={() => auth ? (<Component />) : (<Error />)} />
}

export default App
