/**
 * Represents the account component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState } from 'react'
import './Account.css'
import MyListings from './MyListings.js'
import UserQueuedListings from './UserQueuedListings.js'
import Profile from './Profile.js'

/**
 * Function represents the Account component.
 *
 * @returns {React.ReactElement} - React component.
 */
const Account = () => {
  const [showMyListings, setShowMyListings] = useState(true)
  const [showQueuedListings, setShowQueuedListings] = useState(false)
  const [showProfile, setShowProfile] = useState(false)

  /**
   * Opens my listings component.
   *
   * @returns {void}
   */
  const openMyListings = () => {
    if (showMyListings) {
      return
    }
    if (showQueuedListings) {
      setShowQueuedListings(false)
    }
    if (showProfile) {
      setShowProfile(false)
    }
    setShowMyListings(true)
  }

  /**
   * Opens profile component.
   *
   * @returns {void}
   */
  const openProfile = () => {
    if (showProfile) {
      return
    }
    if (showQueuedListings) {
      setShowQueuedListings(false)
    }
    if (showMyListings) {
      setShowMyListings(false)
    }
    setShowProfile(true)
  }

  /**
   * Open queued listings.
   *
   * @returns {void} - Does nothing.
   */
  const openQueuedListings = () => {
    if (showQueuedListings) {
      return
    }
    if (showMyListings) {
      setShowMyListings(false)
    }
    if (showProfile) {
      setShowProfile(false)
    }
    setShowQueuedListings(true)
  }

  return (
    <div >
      <div id="myAccountNav">
            <ul>
              <li onClick={openMyListings}>
                Mina Annonser
              </li>
              <li onClick={openQueuedListings}>
                Står I Kö
              </li>
              <li onClick={openProfile}>
                Profil
              </li>
            </ul>
      </div>
      <div id="myAccountContentContainer">

        {showMyListings && <MyListings />}
        {showQueuedListings && <UserQueuedListings />}
        {showProfile && <Profile />}
      </div>
    </div>
  )
}

export default Account
