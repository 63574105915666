/**
 * Represents the queue page component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useContext } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import './queuePage.css'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Component represents listing queue page for owner.
 *
 * @returns {React.ReactElement} - React component.
 */
const QueuePage = () => {
  const [data, setData] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)
  const [fetchQueue, setFetchQueue] = useState(true)
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const { id } = useParams()
  const history = useHistory()

  /**
   * Get queue data.
   */
  const getQueue = async () => {
    let status
    await fetch(`${process.env.REACT_APP_BASE_URL}/queue/owner/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      status = res.status
      return res.json()
    }).then((json) => {
      if (status === 200) {
        setData(json)
        setHasLoaded(true)
      } else {
        history.push('/err')
      }
    }).catch(err => {
      console.error(err)
    })
  }

  if (fetchQueue) { // Get queue on load
    getQueue() // Get queue from backend
    setFetchQueue(false)
  }

  /**
   * Removes user from queue.
   *
   * @param {number} i - Index of user to remove
   */
  const handleOnRemove = async (i) => {
    const confirm = window.confirm('Bekräfta att du vill ta bort användaren "' + data.queueUserDetails[i].username + '" från kön.')
    if (!confirm) {
      return
    }

    let status
    await fetch(`${process.env.REACT_APP_BASE_URL}/queue/owner/remove/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'CSRF-Token': csrfToken.csrfToken
      },
      body: JSON.stringify({ index: i })
    }).then((res) => {
      status = res.status
      return res.json()
    }).then(() => {
      if (status === 200) {
        getQueue()
      }
    }).catch(err => {
      console.error(err)
    })
  }

  // Table for users in the queue
  const userQueueList = (
    <>
      <table>
        <tbody>
          <tr>
          <th>Plats</th>
          <th>Användarnamn</th>
          <th>Telefonnummer</th>
          <th>Email</th>
          <th>Ta bort</th>
        </tr>
        {data.queueUserDetails && data.queueUserDetails.map((d, i) => {
          return (
            <tr key={i}>
              <td>{i + 1}</td>
              <td>{d.username}</td>
              <td>{d.phoneNumber}</td>
              <td>{d.email}</td>
              <td id="remove" onClick={() => handleOnRemove(i)}>X</td>
            </tr>
          )
        })}
        </tbody>
      </table>
    </>
  )

  return (
    <div id="queuePage">
      <h1>Användare som står i kö på annonsen:</h1>
      <h2>{data.title}</h2>
      {hasLoaded && (data.queueUserDetails.length > 0 ? userQueueList : <h2>Det finns inga användare som står i kö på denna annons.</h2>)}
    </div>
  )
}

export default QueuePage
