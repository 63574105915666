/**
 * Represents the login component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import './Login.css'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Represents the login component.
 *
 * @param {object} root0 - Listing props.
 * @param {Function} root0.close - Closes the component.
 * @param {boolean} root0.setAuth - Updates auth state.
 * @param {Function} root0.openRegister - Opens register component.
 * @param {boolean} root0.setModal - Opens or closes modal.
 * @param {string} root0.setModalContent - Updates modal content.
 * @returns {React.ReactElement} - React component.
 */
const Login = ({ close, setAuth, openRegister, setModal, setModalContent }) => {
  const [getNewCsrfToken, setGetNewCsrfToken] = useState(true)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const { updateCsrfToken } = useContext(GlobalCsrfTokenStateContext)
  const history = useHistory()

  if (getNewCsrfToken) { // Creates new csrf token each time Login component load.
    updateCsrfToken()
    setGetNewCsrfToken(false)
  }

  /**
   * Handles response after posting login credentials to backend.
   *
   * @param {number} status - Status code from login request.
   */
  const handleResponse = (status) => {
    // Reponse message (if status is not equal 200)
    if (status === 200) {
      setAuth(true)
      close() // Close window
      history.push('/konto') // Open account page
    } else if (status === 400) {
      setModalContent('Användarnamn och/eller lösenord för långt')
      setModal(true)
    } else if (status === 401) {
      setModalContent('Inloggningen misslyckades. Kontrollera dina användaruppgifter')
      setModal(true)
    } else {
      setModalContent('Ett okänt fel har inträffat, försök igen senare.')
      setModal(true)
    }
  }

  /**
   * Handles login submit.
   *
   * @param {object} e - Event object.
   */
  const handleSubmit = (e) => {
    e.preventDefault()
    // Validate form data
    if (username.trim().length > 0 && password.trim().length > 0) {
      if (username.trim().length > 20) {
        setModalContent('Användarnamnet är för långt (Max 20)')
        setModal(true)
      } else if (password.trim().length > 100) {
        setModalContent('Lösenordet är för långt (Max 100)')
        setModal(true)
      } else {
        const userData = {
          username,
          password
        }
        let statusCode = 0
        fetch(`${process.env.REACT_APP_BASE_URL}/auth/login`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken.csrfToken
          },
          body: JSON.stringify(userData)
        }).then(res => {
          statusCode = res.status
          return res.json()
        }).then(async (json) => {
          handleResponse(statusCode)
        }).catch(err => {
          console.error(err)
          setModalContent('Ett okänt fel har inträffat, försök igen senare.')
          setModal(true)
        })
      }
    } else {
      setModalContent('Ange både användarnamn och lösenord!')
      setModal(true)
    }
  }

  return (
      <>
        <form id="loginForm" onSubmit={handleSubmit}>
          <label htmlFor="username">Användarnamn:</label><br>
          </br><input type="text" id="username" name="username" maxLength="20" value={username} onChange={(e) => setUsername(e.target.value)}></input><br>
          </br><label htmlFor="password">Lösenord:</label><br>
          </br><input type="password" id="password" name="password" maxLength="100" value={password} onChange={(e) => setPassword(e.target.value)}></input><br>
          </br><button className="loginBtn" type="submit">Logga in</button>
        </form>
        <p id="loginFormNoAccountText">Har du inget konto?</p>
        <button onClick={openRegister} className="loginBtn" id="loginCreateAccountBtn">Skapa Konto</button>
      </>
  )
}

export default Login
