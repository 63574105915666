/**
 * Represents the question component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState } from 'react'
import './Question.css'

/**
 * Component handles a question and answer.
 *
 * @param {object} root0 - Component props.
 * @param {object} root0.props - A prop.
 * @returns {React.ReactElement} - React component.
 */
const Question = ({ props }) => {
  const { id, title, answer } = props // Question/answer data
  const [showAnswer, setShowAnswer] = useState(false)

  /**
   * Toggles show/hide answer.
   */
  const toggleAnswer = () => {
    if (showAnswer === true) {
      setShowAnswer(false)
    } else {
      setShowAnswer(true)
    }
  }

  return (
    <div id={id} onClick={toggleAnswer} className="questionContainer">
      <div className="questionTitle">
        <h3>{title}</h3>
      </div>
      {showAnswer ? <p>{answer}</p> : null }

    </div>
  )
}

export default Question
