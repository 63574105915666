/**
 * Represents the account window component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState } from 'react'
import './AccountWindow.css'
import Modal from './Modal.js'

/**
 * Represents an AccountWindow component.
 *
 * @param {object} root0 - The components props.
 * @param {string} root0.title - The component Title.
 * @param {Function} root0.close - Removes component.
 * @param {React.Component} root0.Component - The window content.
 * @param {Function} root0.openLogin - Changes to login window.
 * @param {Function} root0.openRegister - Changes to register window.
 * @param {boolean} root0.setAuth - If the user is logged in.
 * @returns {React.ReactElement} - React component.
 */
const AccountWindow = ({ title, close, Component, openLogin, openRegister, setAuth }) => {
  const [modal, setModal] = useState(false)
  const [modalContent, setModalContent] = useState('')

  return (
    <div id="AccountWindowContainer">
        <button id="AccountWindowContainerCloseBtn" onClick={close}>X</button> <br />
        <div id="AccountWindowContainerModalBox">
          {modal && <Modal modalContent={modalContent} />}
        </div>
        <h2>{title}</h2>
        <Component close={close} setAuth={setAuth} openLogin={openLogin} openRegister={openRegister} setModal={setModal} setModalContent={setModalContent} />
    </div>
  )
}

export default AccountWindow
