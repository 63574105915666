/**
 * Represents the listing component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import './Listing.css'
import ListingOwnerMenu from './ListingOwnerMenu.js'

/**
 * Function represents a Listing component.
 *
 * @param {object} root0 - Component params.
 * @param {object} root0.listing - Listing data.
 * @param {string} root0.owner - Listing owners name.
 * @returns {React.ReactElement} - React component.
 */
const Listing = ({ listing, owner }) => {
  const history = useHistory()
  const { id, title, listingType, productImage, price } = listing

  /**
   * Opens listing page.
   *
   * @param {object} e - Event object.
   */
  const handleListingClick = (e) => {
    history.push(`/annonser/${listingType}/${id}`) // Open listing
  }

  return (
    <div id="listing">
      <div onClick={handleListingClick} id="listingImage">
        <img src={productImage} height="110px" alt="alt text" />
      </div>
      <div onClick={handleListingClick} id="listingTitle">
        <h2>{title}</h2>
      </div>
      <div onClick={handleListingClick} id="listingPrice">
        <p>Kr: {price}</p>
      </div>
      {owner === true ? <ListingOwnerMenu id={id} /> : <div onClick={handleListingClick} className="listingOwnerMenu"></div>}
    </div>
  )
}

export default Listing
