/**
 * Represents the latest component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react'
import './Latest.css'
import LatestListing from './LatestListing.js'

/**
 * Represents Latest component.
 * Render latest listings.
 *
 * @returns {React.ReactElement} - React component.
 */
const Latest = () => {
  const [listings, setListings] = useState([])
  const [loaded, setLoaded] = useState(false)

  /**
   * Function fetch latest listings.
   */
  const getData = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/listings/latest`, {
      credentials: 'include'
    }).then(res => {
      return res.json()
    }).then((json) => {
      setListings(json.data)
      setLoaded(true)
    }).catch(err => {
      console.error(err)
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const hasLoadedJsx = (
    <>
    <div data-testid="latest-listings" id="latestFlexContainer">
    {(listings.length < 1) ? <h3 data-testid="no-listings-found-text">Inga Annonser Hittades</h3> : listings.map((listing, i) => <LatestListing key={i} data={listing} />)}
    </div>
    </>
  )

  return (
    <div id="latest">
      <h2>Senaste annonserna</h2>
      {loaded && hasLoadedJsx}
    </div>
  )
}

export default Latest
