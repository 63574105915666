/**
 * Represents the nav account signed out component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './navAccountSignedOut.css'

/**
 * Represents navbar navigation for logged out users.
 *
 * @param {object} root0 - Component params
 * @param {Function} root0.openLogin - Opens login component.
 * @param {Function} root0.openRegister - Opens register component.React
 * @returns {React.ReactElement} - React component.
 */
const NavAccountSignedOut = ({ openLogin, openRegister }) => {
  return (
    <div id="navAccountSignedOut">
      <div id="loginRegisterBtn">
        <button onClick={openLogin}>Logga in</button>
        <button onClick={openRegister}>Registrera</button>
      </div>
      <div id="loginRegisterMobile">
        <span className="link" onClick={openLogin}>Logga in</span>
        <span className="link" onClick={openRegister}>Registrera</span>
      </div>
    </div>
  )
}

export default NavAccountSignedOut
