/**
 * Represents the listing page component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import './ListingPage.css'
import ListingPageListing from './ListingPageListing.js'
import Error from './Error.js'

/**
 * Represents ListingPage component.
 *
 * @param {object} root0 - Component props.
 * @param {boolean} root0.auth - If user is logged in
 * @returns {React.ReactElement} - React component.
 */
const ListingPage = ({ auth }) => {
  const [exist, setExist] = useState(true)
  const [hasLoaded, setHasLoaded] = useState(false)
  const [listing, setListing] = useState({})
  const [isOwner, setIsOwner] = useState(false)
  const [showListing, setShowListing] = useState(true)

  const { id } = useParams()

  useEffect(() => {
    // Get listing data
    fetch(`${process.env.REACT_APP_BASE_URL}/listings/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.status === 404) {
        setExist(false)
      }
      return res.json()
    }).then((json) => {
      setListing(json.foundListing[0])
      setIsOwner(json.foundListing[0].isOwner)
      setHasLoaded(true)
      setShowListing(true)
    }).catch(err => {
      console.error(err)
      setHasLoaded(true)
    })
  }, [id])

  const content = (
    <>
      {showListing && <ListingPageListing auth={auth} listing={listing} isOwner={isOwner} id={id} />}
    </>
  )

  return (
    <div id="listingPageContainer">
      {hasLoaded ? (exist ? content : <Error />) : null}
    </div>
  )
}

export default ListingPage
