/**
 * Represents the help component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './Help.css'
import Questions from './Questions.js'
import HelpForm from './HelpForm.js'

/**
 * Represents Help component.
 *
 * @returns {React.ReactElement} - React component.
 */
function Help () {
  return (
    <div id="helpElement">
      <div id="helpTitle">
          <div id="text">
            <h1>Frågor &amp; svar</h1>
          </div>
      </div>
      <div id="helpQuestions">
        <Questions />
      </div>
      <div id="helpForm">
        <HelpForm />
      </div>
    </div>
  )
}

export default Help
