/**
 * Represents the cookie info component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './cookieInfo.css'

/**
 * Function represents the cookie information component.
 *
 * @returns {React.ReactElement} - React component.
 */
const CookieInfo = () => {
  return (
    <div id="cookieInfo">
      <h1>Cookies</h1>
      <p>Webbplatsen "market-client-1dv613.netlify.app" använder sig av två cookies. Inga cookies lämnas ut till tredje part eller används i marknadsföringssyfte. Syftet med dessa två cookies är att hantera användarens session (som används för att kunna vara inloggad) samt att lagra om användaren godkänt denna cookie policy. Den första kakan heter "userSession" och används för att servern ska veta vilken användare det är som vill komma åt datan. Den andra heter "cookieConsent" och används för att lagra om användaren har godkänt cookie meddelandet första gången webbplatsen öppnades.</p>
    </div>
  )
}

export default CookieInfo
