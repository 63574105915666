/**
 * Represents the edit listing component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useEffect, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import ListingForm from './ListingForm.js'
import './editListing.css'
import GlobalCsrfTokenStateContext from '../contexts/GlobalCsrfTokenStateContext'

/**
 * Function represents ListingPageEdit component.
 *
 * @returns {React.ReactElement} - React component.
 */
const ListingPageEdit = () => {
  const [formData, setFormData] = useState({})
  const [hasLoaded, setHasLoaded] = useState(false)
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [modal, setModal] = useState({ active: false, content: '' })
  const csrfToken = useContext(GlobalCsrfTokenStateContext)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    // Get listing data
    fetch(`${process.env.REACT_APP_BASE_URL}/listings/auth/${id}`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      if (res.status === 404 || res.status === 401 || res.status === 500) {
        history.push('./#') // 404 page
      }
      return res.json()
    }).then((json) => {
      setFormData(json.foundListing[0])
      setHasLoaded(true)
    }).catch(err => {
      console.error(err)
      setHasLoaded(true)
    })
  }, [history, id])

  /**
   * Function submit form.
   *
   * @param {object} e - Submit event.
   * @param {object} formData - User data from form.
   */
  const handleSubmit = (e, formData) => {
    e.preventDefault()

    const { title, productImage, price, listingType, description, category } = formData
    const data = {
      title,
      productImage,
      price,
      listingType,
      description,
      category
    }
    if (!disableSubmit) {
      setDisableSubmit(true)
      // Validate form data
      if ((formData.title.trim().length > 0 && formData.title.trim().length <= 50) && formData.productImage.length > 0 && (formData.description.trim().length > 0 && formData.description.trim().length <= 5000) && formData.category.trim().length > 0 && (formData.price.trim().length > 0 && formData.price.trim().length <= 20) && formData.listingType.trim().length > 0) {
        const { category } = formData
        if (!(category === 'electronics' || category === 'vehicles' || category === 'leisure' || category === 'household' || category === 'furnishings' || category === 'clothes' || category === 'toys' || category === 'other' || category === 'choose')) {
          setModal({ active: true, content: 'Ogiltig kategori' })
          setDisableSubmit(false)
          return
        }
        // Update listing
        let status = null
        fetch(`${process.env.REACT_APP_BASE_URL}/listings/update/${id}`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'CSRF-Token': csrfToken.csrfToken
          },
          body: JSON.stringify(data)
        }).then(res => {
          status = res.status
          return res.json()
        }).then(json => {
          if (status === 200) {
            history.push(`/annonser/${formData.listingType}/${id}`)
          } else {
            setModal({ active: true, content: `Uppdatera annons: Något gick fel: ${json.msg}, ${status}` })
            setDisableSubmit(false)
            console.error('Response not equal status 200')
          }
        }).catch(err => console.error(err))
      } else {
        setModal({ active: true, content: 'Fyll i alla fält!' })
        setDisableSubmit(false)
      }
    } else {
      setModal({ active: true, content: 'En annons skapas redan!' })
      console.error('skapa annons tryckt mer än en gång innan respons!')
    }
  }

  return (
    <div id="listingPageEdit">
      {hasLoaded && <ListingForm title={`Ändra: ${formData.title}`} submitBtnText='Ändra Annons' modal={modal} loadFormContent={formData} handleSubmit={handleSubmit} />}
    </div>
  )
}

export default ListingPageEdit
