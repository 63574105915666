/**
 * Represents the listing page listing component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import ListingPageIsOwnerMenu from './ListingPageIsOwnerMenu.js'
import ListingPageQueue from './ListingPageQueue.js'
import './ListingPageListing.css'

/**
 * Represents a listing.
 *
 * @param {object} root0 - Component props.
 * @param {object} root0.listing - Listing data.
 * @param {boolean} root0.isOwner - If the current user owns the listing.
 * @param {boolean} root0.auth - If user is logged in
 * @param {string} root0.id - Unique listing id.
 * @returns {React.ReactElement} - React component.
 */
const listingPageListing = ({ listing, isOwner, id, auth }) => {
  return (
    <div id="listingPageGridContainer">
        <div className="image">
          <img src={listing.productImage} alt="product" />
        </div>
        <div className="price">
          <p>{listing.price} kr</p>
        </div>
        <div className="date">
          <p>{listing.date}</p>
        </div>
        <div className="title">
          <h1>{listing.title}</h1>
        </div>
        <div className="description">
          <p>{listing.description}</p>
        </div>
        <div className="ownerInfo">
          <div className="owner">
            {isOwner && <ListingPageIsOwnerMenu id={id} />}
          </div>
          <p>Annons skapad av:  <b>{listing.owner}</b></p>
          <p>Annonstyp: {listing.listingType === 'salj' ? 'säljes' : 'köpes'}</p>
          <h2>Kontakta {listing.listingType === 'salj' ? 'säljaren' : 'köparen'}:</h2>
          <p>Telefonnummer: {listing.phoneNumber}</p>
          <p>Epost: {listing.email}</p>
          <p>Ort: {listing.city}</p>
          <ListingPageQueue auth={auth} id={id} />
        </div>
    </div>
  )
}

export default listingPageListing
