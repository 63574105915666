/**
 * Represents the pagination menu component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react'
import './paginationMenu.css'

/**
 * Navigation to each page.
 *
 * @param {object} root0 - Component props.
 * @param {string} root0.totalPages - Number of pages.
 * @param {Function} root0.changePage - Changes page.
 * @returns {React.ReactElement} - React component.
 */
const PaginationMenu = ({ totalPages, changePage }) => {
  const [pageNumber, setPageNumber] = useState(1)
  const [buttonIndex, setButtonIndex] = useState([])

  useEffect(() => {
    setPageNumber(1)
  }, [totalPages])

  useEffect(() => {
    // Render number buttons
    const newNumberButtons = []
    if (totalPages > 7) {
      if ((pageNumber - 4) < 0) {
        for (let i = 0; i < 7; i++) {
          if (i < totalPages) {
            newNumberButtons.push(i)
          } else {
            break
          }
        }
      } else if ((pageNumber + 3) > totalPages) {
        const maxPageIndex = totalPages
        for (let i = (maxPageIndex - 7); i < maxPageIndex; i++) {
          newNumberButtons.push(i)
        }
      } else { // In the middle
        for (let i = (pageNumber - 4); i < (pageNumber + 3); i++) {
          newNumberButtons.push(i)
        }
      }
    } else if (totalPages <= 7 && totalPages > 0) {
      for (let i = 0; i < totalPages; i++) {
        newNumberButtons.push(i)
      }
    }
    setButtonIndex(newNumberButtons)
  }, [pageNumber, totalPages])

  /**
   * Changes page.
   *
   * @param {string} buttonType - Type of button in pagination menu.
   * @param {object} e - Event object.
   */
  const handlePageChange = (buttonType, e) => {
    if (buttonType === 'number') {
      const newPageIndex = parseInt(e.target.id)
      setPageNumber(newPageIndex + 1)
      changePage(e.target.id)
    } else if (buttonType === 'first') {
      setPageNumber(1)
      changePage(0)
    } else if (buttonType === 'prev') {
      if ((pageNumber - 1) > 0) {
        setPageNumber(pageNumber - 1)
        changePage(pageNumber - 2)
      }
    } else if (buttonType === 'next') {
      if ((pageNumber + 1) <= totalPages) {
        setPageNumber(pageNumber + 1)
        changePage(pageNumber)
      }
    } else if (buttonType === 'last') {
      setPageNumber(totalPages)
      changePage(totalPages - 1)
    }
    window.scrollTo(0, 0)
  }

  return (
    <div id="paginationMenu">
      {(totalPages > 1) && <>
        <h4>Sidan {pageNumber} av {totalPages}</h4>
        <div id="paginationMenuBtnContainer">
          <button onClick={() => handlePageChange('first')}>&#10092;&#10092;</button>
          <button onClick={() => handlePageChange('prev')}>&#10092;</button>
          {buttonIndex.map((i) => {
            return <button key={i} id={i} onClick={(e) => handlePageChange('number', e)}>{i + 1}</button>
          })}
          <button onClick={() => handlePageChange('next')}>&#10093;</button>
          <button onClick={() => handlePageChange('last')}>&#10093;&#10093;</button>
        </div>
      </>}
    </div>
  )
}

export default PaginationMenu
