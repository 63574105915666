/**
 * Represents the questions component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React, { useState, useEffect } from 'react'
import Question from './Question.js'

/**
 * Component handles all question components.
 *
 * @returns {React.ReactElement} - React component.
 */
const Questions = () => {
  const [questions, setQuestions] = useState([])

  /**
   * Gets questions from backend.
   */
  const getQuestions = async () => {
    await fetch(`${process.env.REACT_APP_BASE_URL}/help/questions`, {
      credentials: 'include'
    }).then((res) => {
      return res.json()
    }).then((json) => {
      setQuestions(json)
    }).catch(err => console.error(err))
  }

  useEffect(() => {
    getQuestions()
  }, [])

  return (
    <>
      {questions.map((question) => {
        return <Question key={question.id} props={question} />
      })}
    </>
  )
}

export default Questions
