/**
 * Represents the start component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import './Start.css'
import Latest from './Latest.js'
import StartSearch from './StartSearch.js'
import StartShortcuts from './StartShortcuts.js'

/**
 * Component represents start page.
 *
 * @returns {React.ReactElement} - React component.
 */
const Start = () => {
  return (
      <div id="start">
        <div id="mainLeft">
        <StartSearch />
        <StartShortcuts id="startShortcuts" />
        </div>
        <div id="rightMenu">
          <Latest id="latest" />
        </div>
      </div>
  )
}

export default Start
