/**
 * Represents the listing page is owner menu component.
 *
 * @author Oliwer Ellréus <oe222ez@student.lnu.se>
 * @version 1.0.0
 */

import React from 'react'
import { useHistory } from 'react-router-dom'
import './ListingPageIsOwnerMenu.css'

/**
 * Component represents listing page owner menu.
 *
 * @param {object} root0 - Component props.
 * @param {string} root0.id - Unique listing id.
 * @returns {React.ReactElement} - React component.
 */
const ListingPageIsOwnerMenu = ({ id }) => {
  const history = useHistory()

  /**
   * Open remove listing page.
   */
  const handleRemoveListing = () => {
    history.push(`/annonser/radera/${id}`)
  }

  /**
   * Open edit listing page.
   */
  const handleEditListing = () => {
    history.push(`/annonser/andra/${id}`)
  }

  /**
   * Open Listing queue.
   */
  const handleOpenListingQueue = () => {
    history.push(`/annonser/ko/${id}`)
  }

  return (
    <div>
      <ul id="listingPageOwnerMenu">
        <li onClick={handleRemoveListing}>Ta Bort</li>
        <li onClick={handleEditListing}>Redigera</li>
        <li onClick={handleOpenListingQueue}>Kö</li>
      </ul>
    </div>
  )
}

export default ListingPageIsOwnerMenu
